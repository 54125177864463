import * as React from "react";
// Remember that all const are suffix _svg

const close_svg = (
  <svg
    className={"close_svg"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15.556 15.566"
  >
    <path
      fill="#050c58"
      d="M13.679 15.241 7.79 9.352l-5.9 5.889a1.107 1.107 0 1 1-1.566-1.562L6.22 7.79l-5.9-5.9A1.1073 1.1073 0 1 1 1.886.324l5.9 5.9 5.889-5.9a1.1045 1.1045 0 1 1 1.562 1.562l-5.889 5.9 5.889 5.889a1.1045 1.1045 0 1 1-1.562 1.562Z"
    />
  </svg>
);

export { close_svg };
