import * as React from "react";
import { useEffect, useLayoutEffect, useState } from "react";

import BannerCookiesSettings from "../GTM/BannerCookiesSettings/BannerCookiesSettings";
import { getCookie, setCookieFalse, setCookieTrue } from "./CookiesRepository";
import GoogleTagManager from "./GoogleTagManager";

export const enum StatusCookie {
  UNDEFINED,
  CANCEL,
  ACCEPT,
}

export const ID_SETTING_COOKIES = "settings-cookies";

export const pushDatalayer = (event: string): void => {
  if (getCookie() === "true") {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dataLayer.push({
      event,
    });
  }
};
export const callAnalytics = (): void => {
  if (getCookie() === "true") {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dataLayer.push({ event: "route-change" });
  }
};
const BannerCookies = (): JSX.Element => {
  const [cookieTag, setCookieTag] = useState<StatusCookie>(StatusCookie.UNDEFINED);
  const [is_viewSettings, setViewSetting] = useState<boolean>(false);
  const [is_load, setIsLoad] = useState<boolean>(false);

  useLayoutEffect(() => {
    switch (getCookie()) {
      case "true":
        setCookieTag(StatusCookie.ACCEPT);
        break;

      case "false":
        setCookieTag(StatusCookie.CANCEL);
        break;

      default:
        setCookieTag(StatusCookie.UNDEFINED);
        break;
    }
    setIsLoad(true);
  }, []);

  const setStatusAndStorageCookieToTrue = () => {
    setCookieTag(StatusCookie.ACCEPT);
    setCookieTrue();
    setViewSetting(false);
  };
  const setStatusAndStorageCookieToFalse = () => {
    setCookieTag(StatusCookie.CANCEL);
    setCookieFalse();
    setViewSetting(false);
  };

  useEffect(() => {
    if (!is_viewSettings) return;
    const top: number | undefined = document.getElementById(ID_SETTING_COOKIES)?.offsetTop;
    if (!top) return;
    window.scrollTo({
      top,
      left: 0,
      behavior: "smooth",
    });
  }, [is_viewSettings]);
  return (
    <>
      {cookieTag === StatusCookie.UNDEFINED && is_load && (
        <div className={"o-banner-cookies"}>
          <div className="c-banner-cookies">
            <div className={"m-banner-cookies__info"}>
              <p className={"a-banner-cookies__title"}>COOKIES</p>
              <p>
                Utilizamos cookies propias y de terceros para analizar nuestros servicios y mostrarle publicidad
                relacionada con sus preferencias en base a un perfil elaborado a partir de sus hábitos de navegación
                (por ejemplo, páginas visitadas).
              </p>
              <p>
                Puede obtener más información y configurar sus preferencias{" "}
                <a
                  className={"a-banner-cookies__link"}
                  title={"Ver política de cookies"}
                  href="/legal/politica-de-cookies/"
                  target="_blank"
                >
                  aquí
                </a>
              </p>
            </div>

            <div className="m-banner-cookies__buttons">
              <button className={"a-link"} onClick={setStatusAndStorageCookieToFalse}>
                {" "}
                Rechazar{" "}
              </button>
              <button className={"a-button"} onClick={setStatusAndStorageCookieToTrue}>
                Aceptar
              </button>
            </div>
          </div>
        </div>
      )}
      {cookieTag === StatusCookie.ACCEPT && <GoogleTagManager />}
      {cookieTag > 0 && (
        <button
          className={"a-link"}
          onClick={() => {
            setViewSetting(true);
          }}
        >
          Configuración cookies
        </button>
      )}
      {is_viewSettings && (
        <BannerCookiesSettings
          cookieTag={cookieTag}
          setViewSetting={setViewSetting}
          changeToTrue={setStatusAndStorageCookieToTrue}
          changeToFalse={setStatusAndStorageCookieToFalse}
        />
      )}
    </>
  );
};
export default BannerCookies;
