import Cookies from "js-cookie";

const setCookieTrue = (): void => {
  Cookies.set("gdpr-google-tag-manager", "true");
};

const setCookieFalse = (): void => {
  Cookies.set("gdpr-google-tag-manager", "false");
};

function getCookie(): string | undefined {
  return Cookies.get("gdpr-google-tag-manager");
}

export { getCookie, setCookieFalse, setCookieTrue };
