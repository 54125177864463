import * as React from "react";
import { PropsWithChildren } from "react";

import { check_svg, fail_svg } from "../../Forms/components/elements_form";
import { close_svg } from "../../Modals/elements_modal";
import { ID_SETTING_COOKIES, StatusCookie } from "../BannerCookies";

type PropsBannerCookiesSettings = PropsWithChildren<{
  changeToTrue: () => void;
  changeToFalse: () => void;
  setViewSetting: (status: boolean) => void;
  cookieTag: StatusCookie;
}>;

const BannerCookiesSettings: React.FC<PropsBannerCookiesSettings> = (props: PropsBannerCookiesSettings) => {
  return (
    <div id={ID_SETTING_COOKIES} className={"o-banner-settings-cookies"}>
      <button
        className={"m-banner-cookies__close"}
        onClick={() => {
          props.setViewSetting(false);
        }}
        aria-label={"cerrar"}
      >
        <div>{close_svg}</div>
        <span>Cerrar</span>
      </button>
      <div className="c-banner-settings-cookies">
        <div>
          <div className={"m-banner-cookies__info"}>
            <p className={"a-banner-cookies__title"}>Configuración cookies</p>
          </div>
        </div>
      </div>
      <div className="c-banner-cookies__table">
        <table className={"m-banner-cookies__table"}>
          <tr>
            <th colSpan={3}>COOKIES CONTROLADAS POR EL EDITOR</th>
            <th align={"center"} colSpan={2}>
              <b className={`${props.cookieTag === StatusCookie.ACCEPT ? "is-success" : "is-danger"}`}>
                {props.cookieTag === StatusCookie.ACCEPT ? "ACEPTADAS" : "RECHAZADAS"}
              </b>
            </th>
          </tr>
          <tr>
            <th>Propiedad</th>
            <th>Cookie</th>
            <th>Finalidad</th>
            <th align={"center"}>Plazo</th>
            <th align={"center"}>Estado</th>
          </tr>
          <tr>
            <td>MECANICA.AR</td>
            <td>_ga</td>
            <td>ID utiliza para identificar a los usuarios</td>
            <td align={"center"}>En 2 años</td>
            <td
              className={`m-banner-cookies__status ${
                props.cookieTag === StatusCookie.ACCEPT ? "is-success" : "is-danger"
              }`}
            >
              {props.cookieTag === StatusCookie.ACCEPT ? check_svg : fail_svg}
            </td>
          </tr>
          <tr>
            <td>MECANICA.AR</td>
            <td>_gat</td>
            <td>
              Se utiliza para monitorizar el número de peticiones al servidor de Google Analytics cuando se utiliza el
              Administrador de etiquetas Google
            </td>
            <td align={"center"}>Sesión</td>
            <td
              className={`m-banner-cookies__status ${
                props.cookieTag === StatusCookie.ACCEPT ? "is-success" : "is-danger"
              }`}
            >
              {props.cookieTag === StatusCookie.ACCEPT ? check_svg : fail_svg}
            </td>
          </tr>
          <tr>
            <td>MECANICA.AR</td>
            <td>_gid</td>
            <td>ID utiliza para identificar a los usuarios durante 24 horas después de la última actividad</td>
            <td align={"center"}>En 20 horas</td>
            <td
              className={`m-banner-cookies__status ${
                props.cookieTag === StatusCookie.ACCEPT ? "is-success" : "is-danger"
              }`}
            >
              {props.cookieTag === StatusCookie.ACCEPT ? check_svg : fail_svg}
            </td>
          </tr>
          <tr>
            <th colSpan={6}>COOKIES DE TERCEROS</th>
          </tr>
          <tr>
            <th align={"center"} colSpan={2}>
              Editor
            </th>
            <th colSpan={3} align={"center"}>
              Editor Política de privacidad
            </th>
          </tr>
          <tr>
            <td colSpan={2}>Google Analytics</td>
            <td colSpan={3}>
              <a
                href="https://privacy.google.com/take-control.html"
                target={"_blank"}
                rel={"noopener nofollow noreferrer"}
              >
                https://privacy.google.com/take-control.html
              </a>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>PHP.net</td>
            <td colSpan={3}>
              <a href="https://www.php.net/privacy.php" target={"_blank"} rel={"noopener nofollow noreferrer"}>
                https://www.php.net/privacy.php
              </a>
            </td>
          </tr>
        </table>
      </div>
      <div className="c-banner-settings-cookies">
        <div className="m-banner-cookies__buttons">
          <button className={"a-link"} onClick={props.changeToFalse}>
            Rechazar
          </button>
          <button className={"a-button a-button--orange"} onClick={props.changeToTrue}>
            Aceptar todas las Cookies
          </button>
        </div>
        <p className={"m-banner-cookies__footer"}>
          Puede obtener más información {""}
          <a
            className={"a-banner-cookies__link"}
            title={"Ver política de cookies"}
            href="/legal/politica-de-cookies/"
            target="_blank"
          >
            aquí
          </a>
        </p>
      </div>
    </div>
  );
};

export default BannerCookiesSettings;
