import * as React from "react";
// Remember that all const are suffix _svg

const update_svg = (
  <svg xmlns="http://www.w3.org/2000/svg" width="17.84" height="17.833" viewBox="0 0 17.84 17.833">
    <g id="upload-svgrepo-com_1_" data-name="upload-svgrepo-com (1)" transform="translate(-11 -10.986)">
      <g id="Grupo_171" data-name="Grupo 171" transform="translate(11 10.986)">
        <path
          id="Trazado_161"
          data-name="Trazado 161"
          d="M119.623,16.83l2.982-3.208v9.659a.743.743,0,1,0,1.485,0V13.622l2.982,3.208a.745.745,0,0,0,1.092-1.012l-4.271-4.595a.746.746,0,0,0-1.089,0l-4.271,4.595a.743.743,0,1,0,1.089,1.012Z"
          transform="translate(-114.427 -10.986)"
          fill="#5cb4e1"
        />
        <path
          id="Trazado_162"
          data-name="Trazado 162"
          d="M28.1,341.2a.741.741,0,0,0-.743.743v3.583H12.485v-3.583a.743.743,0,0,0-1.485,0v4.325a.741.741,0,0,0,.743.743H28.1a.741.741,0,0,0,.743-.743v-4.325A.743.743,0,0,0,28.1,341.2Z"
          transform="translate(-11 -329.178)"
          fill="#5cb4e1"
        />
      </g>
    </g>
  </svg>
);

const check_svg = (
  <svg xmlns="http://www.w3.org/2000/svg" width="13.867" height="12.133">
    <path
      d="M10.77.663a1.733 1.733 0 0 1 2.85 1.962l-.12.176-6.937 8.67a1.734 1.734 0 0 1-2.424.3l-.164-.145L.508 8.159a1.733 1.733 0 0 1 2.288-2.6l.163.144 2.085 2.085Z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);

const fail_svg = (
  <svg
    className={"fail_svg"}
    xmlns="http://www.w3.org/2000/svg"
    width="20.536"
    height="20.536"
    viewBox="0 0 20.536 20.536"
  >
    <line
      id="Line_38"
      data-name="Line 38"
      x2="17"
      y2="17"
      transform="translate(1.768 1.768)"
      fill="none"
      stroke="#ac4242"
      strokeWidth="5"
    />
    <line
      id="Line_39"
      data-name="Line 39"
      y1="17"
      x2="17"
      transform="translate(1.768 1.768)"
      fill="none"
      stroke="#ac4242"
      strokeWidth="5"
    />
  </svg>
);

export { check_svg, fail_svg, update_svg };
