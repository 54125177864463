exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-sanity-post-blog-slug-current-tsx": () => import("./../../../src/pages/blog/{sanityPostBlog.slug__current}.tsx" /* webpackChunkName: "component---src-pages-blog-sanity-post-blog-slug-current-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-aviso-legal-tsx": () => import("./../../../src/pages/legal/aviso-legal.tsx" /* webpackChunkName: "component---src-pages-legal-aviso-legal-tsx" */),
  "component---src-pages-legal-politica-de-cookies-tsx": () => import("./../../../src/pages/legal/politica-de-cookies.tsx" /* webpackChunkName: "component---src-pages-legal-politica-de-cookies-tsx" */),
  "component---src-pages-legal-politica-de-privacidad-tsx": () => import("./../../../src/pages/legal/politica-de-privacidad.tsx" /* webpackChunkName: "component---src-pages-legal-politica-de-privacidad-tsx" */),
  "component---src-pages-sanity-category-service-slug-current-tsx": () => import("./../../../src/pages/{sanityCategoryService.slug__current}.tsx" /* webpackChunkName: "component---src-pages-sanity-category-service-slug-current-tsx" */),
  "component---src-templates-blog-category-page-tsx": () => import("./../../../src/templates/blog-category-page.tsx" /* webpackChunkName: "component---src-templates-blog-category-page-tsx" */),
  "component---src-templates-blog-page-tsx": () => import("./../../../src/templates/blog-page.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-templates-service-page-tsx": () => import("./../../../src/templates/service-page.tsx" /* webpackChunkName: "component---src-templates-service-page-tsx" */)
}

